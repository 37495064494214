import React, { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Add } from '@material-ui/icons';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import constants from 'constants/Routes';
import { NewSupplierModal } from 'components/NewSupplier/NewSupplierModal';
import { selectSelectedSupplier } from 'store/suppliersList/Selector';
import { startLoading, finishLoading } from 'hooks/useProgress';
import {
  createNewSupplier,
  toggleActivity,
  selectSupplier,
} from 'store/suppliersList/ActionCreator';
import { ThunkDispatch } from 'store/StoreConfig';
import { Button } from 'components/common/Button/Button';
import { MergeSuppliers } from 'components/MergeSuppliers';
import cn from './ActionBar.module.scss';
import { showSnackbarErrorMessage } from 'services/snackbarService';

export const ActionBar = React.memo(() => {
  const selectedSupplier = useSelector(selectSelectedSupplier);
  const dispatch = useDispatch<ThunkDispatch>();
  const history = useHistory();

  const [modalOpened, setModalOpened] = useState(false);

  function handleModalClose(): void {
    setModalOpened(false);
  }

  function handleModalOpen(): void {
    setModalOpened(true);
  }

  async function handleSupplierSave(
    supplierName: string,
  ): Promise<void> {
    startLoading();
    try {
      const supplierId = await dispatch(createNewSupplier(supplierName));
      dispatch(selectSupplier(supplierId));
      history.push(`${constants.supplierCard}/${supplierId}`);
      handleModalClose();
    } catch (err) {
      if ((err as any).response?.data) {
        showSnackbarErrorMessage(err);
        throw err;
      }
    } finally {
      finishLoading();
    }
  }

  function handleIsActiveChange(event: ChangeEvent<HTMLInputElement>): void {
    const { checked } = event.target;
    dispatch(toggleActivity(checked));
  }

  return (
    <div className={cn.root}>
      <Button
        onClick={handleModalOpen}
        color="primary"
        className={cn.button}
      >
        <div className={cn.buttonText}>
          <Add className={cn.newSupplierPlusIcon} />
          New Supplier
        </div>
      </Button>
      <MergeSuppliers className={cn.button} />
      {selectedSupplier && (
        <FormControlLabel
          classes={{ root: cn.activeSupplierFormRoot, label: cn.activeSupplierFormLabel }}
          label="Active Supplier"
          control={(
            <Checkbox
              checked={Boolean(selectedSupplier.isActive)}
              value="checkbox1"
              color="default"
              onChange={handleIsActiveChange}
              classes={{ root: cn.activeSupplierCheckbox, checked: cn.activeSupplierCheckboxChecked }}
            />
          )}
        />
      )}
      {modalOpened && (
        <NewSupplierModal isOpen={modalOpened} handleClose={handleModalClose} handleSave={handleSupplierSave} />
      )}
    </div>
  );
});
