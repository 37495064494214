import React from 'react';

import CloseIcon from '@material-ui/icons/Close';

import constants from 'constants/Routes';
import { selectIsMenuOpened } from 'store/actionBar/Selector';
import { getSuppliersList as getSuppliersListAction } from 'store/suppliersList/ActionCreator';
import { selectSuppliersList, selectSelectedSupplier, selectSelectedSortColumnId, selectSelectedSortAsc } from 'store/suppliersList/Selector';
import { ClickMenu, clickMenu as menuClickAction } from 'store/actionBar/ActionCreator';
import { Supplier } from 'store/suppliersList/types';
import {
    Column, OnClickEventArgs, OnRenderedEventArgs, OnRowsChangedEventArgs, SingleColumnSort, SlickGrid, SlickgridReact
} from 'slickgrid-react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { AppState } from 'store/RootReducer';

import cn from './LeftMenu.module.scss';

let grid: SlickGrid;

type MapStateToProps = {
  isMenuOpen: boolean;
  suppliersList: Supplier[];
  selectedSupplier?: Supplier;
  sortColumnId?: string;
  sortAsc?: boolean;
};

type MapDispatchToProps = {
  clickMenu: ClickMenu;
};

type MenuComponentProps = MapStateToProps & MapDispatchToProps & RouteComponentProps;

const columnDefinitions = [
    {
        id: 'name', name: 'Supplier Name', field: 'name', sortable: true, filterable: true, minWidth: 100, enableAutoTooltips: true,
        formatter: (row: number, cell: number, value: any, column: Column) => `<span title="${value || ''}">${value || ''}</span>`,
        customTooltip: {
            useRegularTooltip: true
        },
    },
    { id: 'specificationsCount', name: 'Number of bookings', field: 'nrOfShipments', sortable: true, minWidth: 100 }
];

const gridOptions = {
    autoResize: {
        bottomPadding: 65,
    },
    enableAutoResize: true,
    gridWidth: 600,
    enableSorting: true,
    enableFiltering: true,
    autoParseInputFilterOperator: false,
    defaultColumnSortFieldId: 'name'
};

export const SrmLeftMenuComponent = React.memo(({
  isMenuOpen,
  suppliersList,
  selectedSupplier,
  sortColumnId,
  sortAsc,
  clickMenu,
  history,
}: MenuComponentProps) => {

  function handleMenuClick(): void {
    clickMenu(!isMenuOpen);
  }

  function comparer(a: any, b: any) {
    const sortDir = sortAsc ? 1 : -1;
    const columnId = sortColumnId as keyof Supplier;
    return sortDir * (a[columnId] === b[columnId] ? 0 : (a[columnId] > b[columnId] ? 1 : -1));
  }

  function paintCells(rowIndex: number): void {
    grid.setCellCssStyles("highlight-row", {
      [rowIndex]: { 
       name: "highlight",
       specificationsCount: "highlight"
      }
     }
    );
  }

  function handleRendered(e: CustomEvent<{ eventData: any; args: OnRenderedEventArgs; }>): void {
    grid = e.detail.args.grid;
    const rowIndex = grid.getData().getRowById(selectedSupplier?.id ?? 0);
    const sortColumns = grid.getSortColumns();

    if (rowIndex)
      paintCells(rowIndex);

    // Sort grid by column 'name' by ascending and set sort glyph
    if (sortColumns.length === 0) {
      grid.getData().sort(comparer, true);
      grid.setSortColumn('name', true);
    }
  }

  function handleClicked(e: CustomEvent<{ eventData: any; args: OnClickEventArgs; }>): void {
    grid.removeCellCssStyles("highlight-row");
    const rowIndex = e.detail.args.row;
    var dataItem = e.detail.args.grid.getDataItem(rowIndex);

    history.push(`${constants.supplierCard}/${dataItem.id}`);
    paintCells(rowIndex);
  }

  function handleRowsChanged(e: CustomEvent<{ eventData: any; args: OnRowsChangedEventArgs; }>): void {
    grid.removeCellCssStyles("highlight-row");
    const rowIndex = e.detail.args.dataView.getRowById(selectedSupplier?.id ?? 0);
    const rowData = e.detail.args.dataView.getItem(rowIndex ?? 0);

    if (rowData?.id === selectedSupplier?.id)
      paintCells(rowIndex ?? 0);
  }

  function handleSorted(e: CustomEvent<{ eventData: any; args: SingleColumnSort; }>): void {
    const sortColumns = grid.getSortColumns();

    // Handle variable changes
    sortColumnId = sortColumns[0].columnId as string;
    sortAsc = e.detail.args.sortAsc;

    if (sortColumns.length > 0)
      grid.getData().sort(comparer, sortAsc); 
  }

  return !gridOptions ? null : (
    <div className={cn.root}>
      <div className={cn.closeButton} onClick={handleMenuClick} role="presentation">
        <CloseIcon />
      </div>
      <div className={cn.tableBodyRoot}>
            <SlickgridReact gridId="grid"
                columnDefinitions={columnDefinitions}
                gridOptions={gridOptions}
                dataset={suppliersList}
                onRendered={handleRendered}
                onClick={handleClicked}
                onRowsChanged={handleRowsChanged}
                onSort={handleSorted} />
      </div>
    </div>
  );
});

const mapStateToProps = (state: AppState): MapStateToProps => ({
  isMenuOpen: selectIsMenuOpened(state),
  suppliersList: selectSuppliersList(state),
  selectedSupplier: selectSelectedSupplier(state),
  sortColumnId: selectSelectedSortColumnId(state),
  sortAsc: selectSelectedSortAsc(state)
});

const mapDispatchToProps = {
  getSuppliersList: getSuppliersListAction,
  clickMenu: menuClickAction,
};

export const SrmLeftMenu = withRouter(connect(mapStateToProps, mapDispatchToProps)(SrmLeftMenuComponent));
