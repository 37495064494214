import axios from 'axios';
import { Log, LogList } from 'store/logs/types';
import { ToDo, ToDoList } from 'store/toDoList/types';
import { BusinessCase } from 'types/BusinessCase';
import {
  BusinessAreasList, CountryList, MyBusinessCases, MyClaimTasks, MySrmTasks, MyCrmTasks, MyCustomers,
} from 'types/types';
import { Customer, Supplier, SupplierId, SuppliersList } from 'store/suppliersList/types';
import { ContactPerson } from 'components/SupplierCard/Tabs/ContactPersonsTab/types';
import { Review } from 'components/SupplierCard/Tabs/ReviewTab/types';
import { routes } from './routes';
import { SearchResultList, SearchTerm } from "store/search/types";
import { ResponsibleSalesList } from 'store/responsibleSales/types';

const api = {
  async getSuppliersList(): Promise<{ suppliersList: SuppliersList }> {
    const { data } = await axios.get(routes.SUPPLIER_CARDS);
    return {
      suppliersList: data || [],
    };
  },
  async getResponsibleSales(): Promise<{ responsibleSales: ResponsibleSalesList }> {
    const { data } = await axios.get(routes.RESPONSIBLE_SALES, { params: { withEmail: true } });
    return {
      responsibleSales: data || [],
    };
  },
  async createNewSupplier(supplier: Supplier): Promise<void> {
    return axios.post(routes.SUPPLIER_CARDS, supplier);
  },
  async editSupplier(supplierId: SupplierId, supplier: Supplier): Promise<void> {
    return axios.put(`${routes.SUPPLIER_CARDS}/${supplierId}`, supplier);
  },
  async getSupplierLogs(supplierId: SupplierId): Promise<{ logs: LogList }> {
    const { data } = await axios.get(routes.SUPPLIER_LOGS(supplierId));
    return {
      logs: data || [],
    };
  },
  addSupplierLog(supplierId: SupplierId, log: Log): Promise<void> {
    return axios.post(routes.SUPPLIER_LOGS(supplierId), log);
  },
  async getToDoList(supplierId: SupplierId): Promise<{ toDoList: ToDoList }> {
    const { data } = await axios.get(routes.TO_DO(supplierId));
    return {
      toDoList: data || [],
    };
  },
  async addToDo(supplierId: SupplierId, todo: ToDo): Promise<{ toDo: ToDo }> {
    const { data } = await axios.post(routes.TO_DO(supplierId), todo);
    return {
      toDo: data,
    };
  },
  editToDo(supplierId: SupplierId, todo: ToDo): Promise<void> {
    return axios.put(`${routes.TO_DO(supplierId)}/${todo.id}`, todo);
  },
  async getSupplierNrs(supplierId: SupplierId): Promise<{ supplierNrs: any }> {
    const { data } = await axios.get(routes.SUPPLIER_NRS(supplierId));
    return {
      supplierNrs: data,
    };
  },
  async getBusinessArea(supplierId: SupplierId): Promise<{ businessAreas: BusinessAreasList }> {
    const { data } = await axios.get(routes.BUSINESS_AREA(supplierId));
    return {
      businessAreas: data || [],
    };
  },
  async getContactPersons(supplierId: SupplierId): Promise<ContactPerson[]> {
    const { data } = await axios.get(routes.CONTACT_PERSONS(supplierId));
    return data;
  },
  async getDestinations(supplierId: SupplierId): Promise<{ destinations: any }> {
    const { data } = await axios.get(routes.DESTINATIONS(supplierId));
    return {
      destinations: data,
    }
  },
  async addContactPerson(supplierId: SupplierId, person: ContactPerson): Promise<{ contactPerson: ContactPerson }> {
    const { data } = await axios.post(routes.CONTACT_PERSONS(supplierId), person);
    return {
      contactPerson: data,
    };
  },
  editContactPerson(supplierId: SupplierId, personId: number, person: any): Promise<void> {
    return axios.put(`${routes.CONTACT_PERSONS(supplierId)}/${personId}`, person);
  },
  async getProfitability(supplierId: SupplierId): Promise<{ profitability: any }> {
    const { data } = await axios.get(routes.PROFITABILITY(supplierId));
    return {
      profitability: data,
    };
  },
  async postSearchTerm(searchTerm: SearchTerm): Promise<{ searchList: SearchResultList, pageNumber: number }> {
    const { data } = await axios.post(routes.SEARCH, searchTerm);
    return {
      searchList: data.data || [],
      pageNumber: data.pageNumber
    }
  },
  async getMyClaimTasks(): Promise<MyClaimTasks> {
    const { data } = await axios.get(routes.MY_CLAIM_TASKS);
    return {
      activeTasks: data.active,
      doneTasks: data.done
    };
  },
  async getMySrmTasks(): Promise<MySrmTasks> {
    const { data } = await axios.get(routes.MY_SRM_TASKS);
    return {
      activeTasks: data.active,
      doneTasks: data.done
    };
  },
  async getMyCrmTasks(): Promise<MyCrmTasks> {
    const { data } = await axios.get(routes.MY_CRM_TASKS);
    return {
      activeTasks: data.active,
      doneTasks: data.done
    };
  },
  async getMyBusinessCases(): Promise<MyBusinessCases> {
    const { data } = await axios.get(routes.MY_BUSINESS_CASES);
    return {
      businessCases: data.map((businessCase: BusinessCase) => (
        { ...businessCase, status: businessCase.status.name }
      )),
    };
  },
  async getMyCustomers(): Promise<MyCustomers> {
    const { data } = await axios.get(routes.MY_CUSTOMERS);
    return {
      customers: data.map((card: Customer) => ({
        ...card,
        customerCard: { id: card.id, name: card.name },
        tb1: Math.round(card.tb1),
        turnover: Math.round(card.turnover),
        percent: card.tb1 === 0 && card.turnover === 0
          ? 0
          : parseFloat(((card.tb1 / card.turnover) * 100).toFixed(1)),
      })),
    };
  },
  async getCountries(): Promise<{ countries: CountryList }> {
    const { data } = await axios.get(routes.COUNTRIES);
    return {
      countries: data || [],
    };
  },
  async mergeCards(fromSupplierId: SupplierId, toSupplierId: SupplierId): Promise<void> {
    return axios.post(routes.MERGE_CARDS(fromSupplierId, toSupplierId), {});
  },
  async getReview(supplierId: SupplierId): Promise<Review> {
    const { data } = await axios.get(routes.REVIEW(supplierId));
    return data;
  },
  async editReview(supplierId: SupplierId, review: any): Promise<void> {
    return axios.post(routes.REVIEW(supplierId), review);
  },
};

export default api;
