import React from 'react';
import { MyClaimTasks } from 'components/MyClaimTasks/MyClaimTasks';
import { MySrmTasks } from 'components/MySRMTasks/MySRMTasks';
import { MyCrmTasks } from 'components/MyCRMTasks/MyCRMTasks';
import { MyBusinessCases } from 'components/MyBusinessCases/MyBusinessCases';
import { MyCustomers } from 'components/MyCustomers/MyCustomers';
import cn from 'containers/MyPage/MyPage.module.scss';

export const MyPage = React.memo(() => {

    return (
        <div className={cn.root}>
            <MySrmTasks/>
            <MyClaimTasks />
            <MyCrmTasks />
            <MyBusinessCases />
            <MyCustomers />
        </div>
    );
});
