import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import api from 'api';
import { MyPageTable } from 'components/common/myPageTable/MyPageTable';
import { Button } from 'components/common/Button/Button';
import { ContactPersonModal } from 'components/SupplierCard/ContactPersonModal/ContactPersonModal';
import { selectSelectedSupplierId } from 'store/suppliersList/Selector';
import { startLoading, finishLoading } from 'hooks/useProgress';
import { SupplierId } from 'store/suppliersList/types';
import { AppState } from 'store/RootReducer';
import { ContactPerson, ContactPersonList } from 'components/SupplierCard/Tabs/ContactPersonsTab/types';
import { showSnackbar } from 'hooks/useSnackbar';
import cn from './ContactPersonsTab.module.scss';

type MapStateToProps = {
  selectedSupplierId: SupplierId;
};

type ContactPersonsTabProps = MapStateToProps;

const ContactPersonsTabComponent = React.memo(({ selectedSupplierId }: ContactPersonsTabProps) => {
  const [data, setData] = useState<ContactPersonList>([]);
  const [opened, setOpened] = useState(false);
  const [editedRow, setEditedRow] = useState<ContactPerson | null>(null);

  const requestData = useCallback(async () => {
    startLoading();
    try {
      const contactPersons = await api.getContactPersons(selectedSupplierId);
      setData(contactPersons || []);
    } catch (err) {
    }
    finishLoading();
  }, [selectedSupplierId]);

  function handleOpenModal(): void {
    setOpened(true);
  }

  function handleCloseModal(): void {
    setOpened(false);
    setEditedRow(null);
  }

  function handleEditRowClick(rowId: number): void {
    setEditedRow(data.find((row) => row.id === rowId) || null);
    setOpened(true);
  }

  async function handleFormSubmit(contactPerson: ContactPerson): Promise<void> {
    if (editedRow && editedRow.id) {
      await api.editContactPerson(selectedSupplierId, editedRow.id, {
        ...contactPerson,
      });
      showSnackbar({
        message: 'Contact person was updated.',
        type: 'success',
      });
      setEditedRow(null);
      requestData();
    } else {
      await api.addContactPerson(selectedSupplierId, contactPerson);
      showSnackbar({
        message: 'Contact person was successfully created.',
        type: 'success',
      });
      requestData();
    }
    setOpened(false);
  }

  useEffect(() => {
    if (selectedSupplierId) {
      requestData();
    }
  }, [selectedSupplierId, requestData]);

  return (
    <div className={cn.tabContainer}>
      <Button
        onClick={handleOpenModal}
        className={cn.button}
      >
        Add New Contact
      </Button>
      <MyPageTable
        tableData={data}
        padding="small"
        filter
        edit
        onRowEdit={handleEditRowClick}
        columns={[
          { name: 'name', title: 'Name' },
          { name: 'email', title: 'Email' },
          { name: 'telephone', title: 'Telephone' },
          { name: 'title', title: 'Title' },
        ]}              
        defaultSorting={[
          { columnName: 'name', direction: 'asc' },
        ]}
      />
      {opened && (
        <ContactPersonModal
          isOpen={opened}
          onClose={handleCloseModal}
          onSubmit={handleFormSubmit}
          editedRow={editedRow}
        />
      )}
    </div>
  );
});

const mapStateToProps = (state: AppState): MapStateToProps => ({
  selectedSupplierId: selectSelectedSupplierId(state),
});

export const ContactPersonsTab = connect(mapStateToProps)(ContactPersonsTabComponent);
