import api from 'api';
import DateService from 'services/DateService';
import { selectToDoList } from 'store/toDoList/Selector';
import { storeSupplier } from 'helpers';
import { ThunkAction } from '../StoreConfig';
import { selectSuppliersList, selectSelectedSupplier } from './Selector';
import {
  ActionTypes,
  AddSupplierAction,
  Supplier,
  SupplierId,
  SuppliersList,
  EditSupplierAction,
  SelectSupplierAction,
  SetSuppliersListAction,
  ToggleActivityAction,
} from './types';

export const selectSupplier = (id: SupplierId): SelectSupplierAction => {
  storeSupplier(id);
  return {
    type: ActionTypes.SELECT_SUPPLIER,
    id,
  };
};

const addSupplier = (supplier: Supplier): AddSupplierAction => ({
  type: ActionTypes.ADD_SUPPLIER,
  supplier,
});

const editSupplier = (supplier: Supplier): EditSupplierAction => ({
  type: ActionTypes.EDIT_SUPPLIER,
  supplier,
});

export const toggleActivity = (active: boolean): ToggleActivityAction => ({
  type: ActionTypes.TOGGLE_ACTIVITY,
  active,
});

const setSuppliersList = (suppliersList: SuppliersList): SetSuppliersListAction => ({
  type: ActionTypes.SET_SUPPLIERS_LIST,
  suppliersList,
});

export type GetSuppliersList = () => ThunkAction<void, SetSuppliersListAction>;

export const getSuppliersList: GetSuppliersList = () => async (dispatch): Promise<void> => {
  const { suppliersList } = await api.getSuppliersList();
  dispatch(
    setSuppliersList(
      suppliersList.map((supplier: Supplier) => {
        return {
          ...supplier
        };
      }),
    ),
  );
};

export const createNewSupplier = (
  supplierName: string,
): ThunkAction<string, AddSupplierAction> => async (dispatch): Promise<string> => {
  const supplierId: string = DateService.generateGuid();
  const newSupplier: Supplier = {
    id: supplierId,
    name: supplierName,
    isActive: false,
    turnover: 0,
    tb1: 0,
    nextToDo: '',
    nrOfShipments: 0,
    supplierCardInsurance: {},
    supplierCardOtherFiles: [{}]
  };
  await api.createNewSupplier(newSupplier);
  dispatch(addSupplier(newSupplier));
  return supplierId;
};

export const editSupplierData = (
  supplierId: string, supplier: Supplier,
): ThunkAction<void, EditSupplierAction> => async (dispatch): Promise<void> => {
  const newSupplier = {
    ...supplier
  };
  await api.editSupplier(supplierId, newSupplier);
  dispatch(editSupplier(newSupplier));
};

export const updateNextToDo = (supplierId: SupplierId): ThunkAction<void, SetSuppliersListAction> => (dispatch, getState): void => {
  try {
    const state = getState();
    const selectedSupplier = selectSelectedSupplier(state);
    if (!selectedSupplier) {
      return;
    }
    const toDoList = selectToDoList(state);
    const minData = toDoList.reduce((current, next) => (current.date < next.date ? current : next), toDoList[0]);
    if (!minData || selectedSupplier.nextToDo !== minData.date) {
      const suppliersList = selectSuppliersList(getState());
      dispatch(setSuppliersList(
        suppliersList.map((supplier: Supplier) => (supplier.id === supplierId
          ? { ...supplier, nextToDo: minData ? minData.date : '' }
          : supplier)),
      ));
    }
  } catch (err) {
  }
};
