import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import cn from './SearchPageActionBar.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsClaimsActive, selectIsSrmActive } from 'store/search/Selector';
import { ThunkDispatch } from 'store/StoreConfig';
import { setClaimsState, setSrmState } from 'store/search/ActionCreator';
import { showSnackbar } from 'hooks/useSnackbar';
import routeConstants from 'constants/Routes';
import { useHistory, useLocation } from 'react-router';

export const SearchPageActionBar = React.memo(() => {
  const history = useHistory();
  const location = useLocation();
  const srmState = useSelector(selectIsSrmActive);
  const claimsState = useSelector(selectIsClaimsActive);
  const dispatch = useDispatch<ThunkDispatch>();
  const searchParams = new URLSearchParams(location.search);
  const isSrm = searchParams.get('IsSrm')?.toString() === 'true' ? true : false;
  const isClaims = searchParams.get('IsClaims')?.toString() === 'true' ? true : false;
  
  const handleClaimsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(srmState) {
      dispatch(setClaimsState(event.target.checked)); 
      searchParams.set('IsClaims', event.target.checked.toString());
      history.push({
        pathname: routeConstants.searchResult,
        search: searchParams.toString(),
      });
    } else {
      showSnackbar({
        message: 'At least one checkbox must be selected.',
        type: 'info',
      });
    }
  };

  const handleSrmChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(claimsState) {
      dispatch(setSrmState(event.target.checked));
      searchParams.set('IsSrm', event.target.checked.toString());
      history.push({
        pathname: routeConstants.searchResult,
        search: searchParams.toString(),
      });
    } else {
      showSnackbar({
        message: 'At least one checkbox must be selected.',
        type: 'info',
      });
    }
  };

  return (
    <div className={cn.root}>
        <FormControlLabel
          classes={{ root: cn.activeSupplierFormRoot, label: cn.activeSupplierFormLabel }}
          label="Claims"
          control={(
            <Checkbox
              defaultChecked={isClaims}
              value={claimsState}
              color="default"
              name="checkedClaims"
              onChange={handleClaimsChange}
              classes={{ root: cn.activeSupplierCheckbox, checked: cn.activeSupplierCheckboxChecked }}
            />
          )}
        />
        <FormControlLabel
          classes={{ root: cn.activeSupplierFormRoot, label: cn.activeSupplierFormLabel }}
          label="SRM"
          control={(
            <Checkbox
              defaultChecked={isSrm}
              value={srmState}
              color="default"
              name="checkedSrm"
              onChange={handleSrmChange}
              classes={{ root: cn.activeSupplierCheckbox, checked: cn.activeSupplierCheckboxChecked }}
            />
          )}
        />
    </div>
  );
});