import { SupplierId } from 'store/suppliersList/types';

const rootSrm = '/api/supplier-cards';
const search = '/api/search';

export const routes = {
  SUPPLIER_CARDS: `${rootSrm}`,
  COUNTRIES: `api/countries`,
  RESPONSIBLE_SALES: '/api/users/responsible-sales',
  SUPPLIER_LOGS: (supplierId: SupplierId): string => `${rootSrm}/${supplierId}/logs`,
  TO_DO: (supplierId: SupplierId): string => `${rootSrm}/${supplierId}/to-do`,
  SUPPLIER_NRS: (supplierId: SupplierId): string => `${rootSrm}/${supplierId}/suppliers`,
  DESTINATIONS: (supplierId: SupplierId): string => `${rootSrm}/${supplierId}/destinations`,
  BUSINESS_AREA: (supplierId: SupplierId): string => `${rootSrm}/${supplierId}/business-area`,
  CONTACT_PERSONS: (supplierId: SupplierId): string => `${rootSrm}/${supplierId}/contacts`,
  PROFITABILITY: (supplierId: SupplierId): string => `${rootSrm}/${supplierId}/profitability`,
  REVIEW: (supplierId: SupplierId): string => `${rootSrm}/${supplierId}/review`,
  SEARCH: `${search}`,
  MY_CLAIM_TASKS: `/api/claim/to-do/getAll`,
  MY_SRM_TASKS: `/api/supplier-cards/to-do/getAll`,
  MY_CRM_TASKS: `/api/crm/to-do/getAll`,
  MY_BUSINESS_CASES: `/api/businessCases/getAll`,
  MY_CUSTOMERS: `/api/customerCards/getAll`,
  MERGE_CARDS: (fromSupplierId: SupplierId, toSupplierId: SupplierId): string => `${rootSrm}/${fromSupplierId}/merge/${toSupplierId}`,
};
