import React from 'react';
import * as PropTypes from 'prop-types';
import { SrmLayout } from 'containers/srm/srmLayoutContainer/SrmLayout';
import ConnectedPrivateRoute from 'hoc/PrivateRoute';
import { useMicrosoftAuth } from '../hooks/useMicrosoftAuth';

export const SrmLayoutRoute = ({ component: Component, toolbar: Toolbar, ...props }) => {
  const { setActiveAccount } = useMicrosoftAuth();

  (async () => {
    await setActiveAccount();
  })();

  return (
    <ConnectedPrivateRoute
      {...props}
      render={componentProps => (
        <SrmLayout toolbar={Toolbar ? <Toolbar /> : null}>
          <Component {...componentProps} />
        </SrmLayout>
      )}
    />
  );
};

SrmLayoutRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.shape(),
  ]),
  toolbar: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.shape(),
  ]),
};
