import { Selector } from '../StoreConfig';
import { SearchResultList } from './types';

export const selectSearchResultList: Selector<SearchResultList> =
    (state) => state.searchResultsList.searchList;

export const selectQuery: Selector<string> =
    (state) => state.searchQuery.query;

export const selectIsCrmActive: Selector<boolean> =
    (state) => state.searchType.crmIsActive;

export const selectIsSrmActive: Selector<boolean> =
    (state) => state.searchType.srmIsActive;

export const selectIsClaimsActive: Selector<boolean> =
    (state) => state.searchType.claimsIsActive;