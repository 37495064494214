import * as graph from '@microsoft/microsoft-graph-client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setIsAuthenticated as setIsAuthenticatedAction } from 'store/microsoftGraph/ActionCreator';
import Cookies from 'universal-cookie';
import { PublicClientApplication } from '@azure/msal-browser';

export type MicrosoftGraphConfig = {
  clientId: string;
  tentantId?: string;
  redirectUri?: string;
  postLogoutRedirectUri?: string;
  scopes?: string[];
};

type UseMicrosoftAuth = {
  login: () => void;
  logout: (redirectUri?: string) => void;
  getUserProfile: () => Promise<boolean>;
  getClient: () => Promise<graph.Client>;
  getDriveId: () => Promise<string>;
  getGroupId: () => Promise<string>;
  setActiveAccount: () => void;
};

const microsoftConfig: MicrosoftGraphConfig = {
  clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID || '',
  tentantId: `https://login.microsoftonline.com/${process.env.REACT_APP_MICROSOFT_TENTANT_ID}`,
  redirectUri: process.env.REACT_APP_MICROSOFT_REDIRECT_URI,
  postLogoutRedirectUri: process.env.REACT_APP_MICROSOFT_POST_LOGOUT_REDIRECT_URI,
  scopes: [
    'User.Read',
    'User.ReadWrite.All',
    'Files.ReadWrite.All',
    'Directory.Read.All',
    'Calendars.ReadWrite',
  ],
};

let groupId: string;
let driveId: string;
const cookie = new Cookies();

export function useMicrosoftAuth(config: MicrosoftGraphConfig = microsoftConfig): UseMicrosoftAuth {
  const dispatch = useDispatch();

  function getPublicClientApplication(config: MicrosoftGraphConfig): any {
    return new PublicClientApplication({
      auth: {
        clientId: config.clientId,
        authority: config.tentantId,
        redirectUri: config.redirectUri,
        postLogoutRedirectUri: config.postLogoutRedirectUri,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
      },
    });
  }

  const msalInstance = getPublicClientApplication(config);

  async function getClient(): Promise<graph.Client> {
    const accessToken = await msalInstance.acquireTokenSilent(
      { scopes: config.scopes },
    );

    return graph.Client.init({
      authProvider: (done) => {
        done(null, accessToken.accessToken);
      },
    });
  }

  const getGroupId = async (): Promise<string> => {
    const client = await getClient();
    const group = process.env.REACT_APP_MICROSOFT_GROUP_NAME;
    const { value } = await client.api(`/groups?$filter=displayName+eq+'${group}'`).get();

    return value?.length ? value[0].id : null;
  };

  const getDriveId = async (): Promise<string> => {
    const client = await getClient();
    if (!groupId) {
      groupId = await getGroupId();
    }
    if (!driveId) {
      const drive = await client
        .api(`groups/${groupId}/drive`)
        .get();
      driveId = drive.id;
    }
    return driveId;
  };

  async function getUserProfile(): Promise<boolean> {
    try {
      const accessToken = await msalInstance.acquireTokenSilent({
        scopes: config.scopes,
      });
      if (accessToken) {
        dispatch(setIsAuthenticatedAction(true));
      }
      return Boolean(accessToken);
    } catch (err) {
      console.log(err);
      dispatch(setIsAuthenticatedAction(false));
      return false;
    }
  }

  async function login(): Promise<void> {
    try {
      await msalInstance.loginPopup({
        scopes: config.scopes, prompt: 'select_account',
      });
      await getUserProfile();
    } catch (err) {
      console.log(err);
      dispatch(setIsAuthenticatedAction(false));
    }
  }

  async function logout(): Promise<void> {
    try {
      const user = await getUserProfile();
      if (user) {
        await msalInstance.logout();
      }
    } catch (err) {
    } finally {
      dispatch(setIsAuthenticatedAction(false));
    }
  }

  async function setActiveAccount(): Promise<void> {
    const cookieAccount = cookie.get('account');
    if (cookieAccount !== undefined) {
      try {
        msalInstance.setActiveAccount(cookieAccount);
        await msalInstance.acquireTokenSilent({
          account: cookieAccount,
          scopes: config.scopes
        });
        dispatch(setIsAuthenticatedAction(true));
      }
      catch (err) {
        console.log(err);
        dispatch(setIsAuthenticatedAction(false));
      }
    }
    else {
      dispatch(setIsAuthenticatedAction(false));
    }
  }

  useEffect(() => {
    const account = msalInstance.getActiveAccount();
    const getUser = async (): Promise<void> => {
      if (account) {
        await getUserProfile();
      }
    };
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    logout,
    login,
    getUserProfile,
    getClient,
    getDriveId,
    getGroupId,
    setActiveAccount
  };
}
