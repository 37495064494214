import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import api from 'api';
import { MyPageTable } from 'components/common/myPageTable/MyPageTable';
import { selectSelectedSupplierId } from 'store/suppliersList/Selector';
import { startLoading, finishLoading } from 'hooks/useProgress';
import { SupplierId } from 'store/suppliersList/types';
import { AppState } from '../../../../store/RootReducer';

type MapStateToProps = {
  selectedSupplierId: SupplierId;
};

type DestinationsTabProps = MapStateToProps;

const DestinationsTabComponent = React.memo(({ selectedSupplierId }: DestinationsTabProps) => {
  const [data, setData] = useState([]);

  const requestData = useCallback(async () => {
    startLoading();
    try {
      const { destinations } = await api.getDestinations(selectedSupplierId);
      setData(destinations);
    } catch (err) {
    }
    finishLoading();
  }, [selectedSupplierId]);

  useEffect(() => {
    if (selectedSupplierId) {
      requestData();
    }
  }, [selectedSupplierId, requestData]);

  return (
    <MyPageTable
      filter
      padding="small"
      tableData={data}
      columns={[
        { name: 'loadingCountry', title: 'Loading Country' },
        { name: 'unloadingCountry', title: 'Unloading Country' },
        { name: 'numberOfShipments', title: 'Number of Shipments' }
      ]}
      defaultSorting={[{ columnName: 'loadingCountry', direction: 'asc' }]}
    />
  );
});

const mapStateToProps = (state: AppState): MapStateToProps => ({
  selectedSupplierId: selectSelectedSupplierId(state),
});

export const DestinationsTab = connect(mapStateToProps)(DestinationsTabComponent);