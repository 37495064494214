import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  DataTypeProvider,
  IntegratedSorting,
  SortingState,
  IntegratedFiltering,
  FilteringState,
  EditingState,
  DataTypeProviderProps,
  RowDetailState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableEditColumn,
  TableFilterRow,
  TableHeaderRow,
  TableBandHeader,
  TableRowDetail,
} from '@devexpress/dx-react-grid-material-ui';
import { Tooltip, Button } from '@material-ui/core';
import { Supplier } from 'store/suppliersList/types';
import routes from 'constants/Routes';
import { numberWithSpaces } from 'utils/string';
import cn from './MyPageTable.module.scss';
import constants from 'constants/Routes';
import { logout as logoutAction } from 'store/authorization/ActionCreator';

type ValueFormatterProps = DataTypeProvider.ValueFormatterProps;

const sortBySupplierName = ((firstSupplier: Supplier, secondSupplier: Supplier): number => {
  if (firstSupplier.name === secondSupplier.name) {
    return 0;
  }
  return firstSupplier.name < secondSupplier.name ? -1 : 1;
});

interface MyPageTableProps {
  tableData: Array<{ [x: string]: any }>;
  columns: Array<{ name: string; title?: string }>;
  columnExtensions?: Array<{
    columnName: string;
    width?: number;
    align?: 'left' | 'right' | 'center';
  }>;
  defaultSorting?: Array<{
    columnName: string;
    direction: 'asc' | 'desc';
  }>;
  padding?: 'small' | 'default';
  filter?: boolean;
  edit?: boolean;
  formatNumberWithSpace?: Array<string>;
  formatBoolean?: Array<string>;
  columnBands?: Array<{
    title: string;
    children: Array<{
      columnName: string;
    }>;
  }>;
  editFormRender?: (row: any, handleClose: () => void) => ReactElement;
  onRowEdit?: (id: number) => void;
  disableTableMinWidth?: boolean;
  booleanIcons?: Array<{
    fieldName: string;
    yes: {
      icon: React.ComponentType<{ htmlColor?: string; className?: string }>;
      color?: string;
    };
    no: {
      icon: React.ComponentType<{ htmlColor?: string; className?: string }>;
      color?: string;
    };
  }>;
  className?: string;
}

type State = {
  expandedRowIds: Array<string | number>;
};

export const MyPageTable = ({
  tableData,
  columns,
  columnExtensions,
  defaultSorting,
  formatNumberWithSpace,
  formatBoolean,
  padding,
  edit,
  editFormRender,
  filter,
  columnBands,
  onRowEdit,
  disableTableMinWidth,
  booleanIcons,
  className,
}: MyPageTableProps) => {
  const [expandedRowIds, setExpandedRowIds] = useState<Array<string | number>>([]);
  const [user, setUser] = useState<any>({});
  
  useEffect(()=>{
    setUser(JSON.parse(localStorage.getItem('user') || '{}'))
  },[])

  function handleExpandedToggle(rowIds: Array<string | number>): void {
    const lastItem = rowIds.pop();
    setExpandedRowIds([lastItem !== undefined ? lastItem : '']);
  }

  const Cell = (props: Table.DataCellProps): ReactElement => {
    const { column, value, children } = props;
    return (
      <Table.Cell
        {...props}
        className={classNames(cn.cell, cn[`${padding}Padding`])}
      >
        {column.name === 'supplierCard' ? (
          <Link to={`${routes.supplierCard}/${value.id}`} className={cn.link}>{value.name}</Link>
        ) : column.name === 'customerCard' ? (
          <a href={redirectToAnotherPortal(`${constants.crmPageUrl}`,`${value.id}`)} className={cn.link}>{value.name}</a>
        ) : (column.name === 'claimCard' ? (
          <a href={redirectToAnotherPortal(`${constants.claimsPageUrl}`,`${value.id}`)} className={cn.link}>{value.claimDisplayNumber}</a>
        ) : (children != null ? (
          (children)
        ) : (<Tooltip title={(<div>{value}</div>)}><span>{value}</span></Tooltip>)))}
      </Table.Cell>
    );
  };

  const StubCell = (props: Table.CellProps): ReactElement => {
    const isFilterCell = props.tableRow.key === 'Symbol(filter)';
    return (
      <Table.StubCell
        {...props}
        className={
          classNames(
            cn.stubCell,
            cn[`${padding}Padding`],
            { [cn.stubCellNoFilter]: editFormRender && !filter },
            { [cn.stubFilterCell]: isFilterCell },
          )
        }
      />
    );
  };

  const HeaderCell = (props: TableHeaderRow.CellProps): ReactElement => {
    const { column: { title } } = props;
    return (
      <Tooltip title={title!}>
        <TableHeaderRow.Cell
          {...props}
          className={classNames(
            cn.headerCell,
            { [cn.stickyHeaderToBand]: !!columnBands },
            cn[`${padding}Padding`],
          )}
        />
      </Tooltip>
    );
  };

  const BandCell = (props: TableBandHeader.CellProps): ReactElement => (
    <TableBandHeader.Cell {...props} className={classNames(cn.bandCell, cn[`${padding}Padding`])} />
  );

  const NoDataCell = (props: Table.NoDataCellProps): ReactElement => (
    <Table.NoDataCell {...props} className={cn.noDataCell} />
  );

  const FilterCell = (props: TableFilterRow.CellProps): ReactElement => (
    <TableFilterRow.Cell {...props} className={classNames(cn.filterCell)} >
      <div
        className={cn.filterInput}
        style={{
          margin: '8px',
          width: '100%'
        }}>
        <FilterCellInternal {...props} />
      </div>
    </TableFilterRow.Cell>
  );

  const FilterCellInternal = ({ filter, onFilter }: any) => (
    <input
      type="search"
      placeholder='Filter...'
      value={filter ? filter.value : ''}
      onChange={e => onFilter(e.target.value ? { value: e.target.value } : null)}
      style={{
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontSize: '14px',
        width: '100%',
        display: 'flex',
        padding: '6px 0px 7px',
        border: 'none',
        outline: 'none'
      }}
    />
  );

  const EditForm = ({ row }: { row: any }) => {
    if (editFormRender) {
      const handleClose = (): void => {
        setExpandedRowIds([]);
      };
      return editFormRender(row, handleClose);
    }
    return <div>No edit form</div>;
  };

  const ToggleEditCell = React.memo((props: TableRowDetail.ToggleCellProps): ReactElement => {
    const { onToggle } = props;
    const { expanded, ...rest } = props;

    return (
      <TableRowDetail.Cell
        {...rest}
        style={{
          padding: 0,
        }}
        className={cn.editCell}
      >
        {(onRowEdit || editFormRender) && (
          <Button
            onClick={onToggle}
            color="primary"
            classes={{
              root: cn.editButton,
            }}
          >
            Edit
          </Button>
        )}
      </TableRowDetail.Cell>
    );
  });

  const EditCell = (props: TableEditColumn.CellProps): ReactElement => (
    <TableEditColumn.Cell
      {...props}
      style={{
        padding: 0,
      }}
      className={cn.editCell}
    >
      {onRowEdit && (
        <Button
          onClick={(): void => onRowEdit(props.row.id)}
          color="primary"
          classes={{
            root: cn.editButton,
          }}
        >
          Edit
        </Button>
      )}
    </TableEditColumn.Cell>
  );

  const EditCellHeader = (props: TableEditColumn.HeaderCellProps): ReactElement => (
    <TableEditColumn.HeaderCell
      {...props}
      style={{
        padding: 0,
      }}
      className={classNames(cn.headerCell, cn[`${padding}Padding`])}
    />
  );

  const TableComponent:
    React.ComponentType<object & { className?: string; style?: React.CSSProperties;[x: string]: any }> = (props) => {
      const { style = {} } = props;
      return (
        <Table.Table
          {...props}
          style={{
            ...style,
            minWidth: disableTableMinWidth ? 'initial' : style.minWidth,
          }}
          className={cn.table}
        />
      );
    };

  const TableContainer:
    React.ComponentType<object & { className?: string; style?: React.CSSProperties;[x: string]: any }> = (props) => (
      <Table.Container
        {...props}
        style={{
          ...props.style,
          overflow: 'visible',
        }}
      />
    );

  const NumberFormatter: React.ComponentType<ValueFormatterProps> = ({ value }: { value: any }) => (
    <span>{numberWithSpaces(value || 0)}</span>
  );

  const PercentFormatter: React.ComponentType<ValueFormatterProps> = ({ value }: { value: any }) => (
    <span>{`${value}%`}</span>
  );

  const BooleanFormatter: React.ComponentType<ValueFormatterProps> = (props) => {
    if (!booleanIcons) {
      return props.value;
    }
    const filter = booleanIcons.filter(x => x.fieldName === props.column.name);
    if (props.value) {
      const Yes = filter[0].yes.icon;
      return <Yes htmlColor={filter[0].yes.color || ''} className={cn.icon} />;
    }
    const No = filter[0].no.icon;
    return <No htmlColor={filter[0].no.color || ''} className={cn.icon} />;
  };

  const redirectToAnotherPortal = useCallback((route: any, id: any) => {
    if (user !== null) {
      var token = user.token
      var name = user.name
      logoutAction();
      return `${route}/redirect/${id}/${token}/${name}`;
    }
    return undefined
  },[user])

  const PercentTypeProvider: React.ComponentType<DataTypeProviderProps> = (props) => <DataTypeProvider formatterComponent={PercentFormatter} {...props} />;

  const NumberTypeProvider: React.ComponentType<DataTypeProviderProps> = (props) => <DataTypeProvider formatterComponent={NumberFormatter} {...props} />;

  const BooleanTypeProvider: React.ComponentType<DataTypeProviderProps> = (props) => <DataTypeProvider formatterComponent={BooleanFormatter} {...props} />;

  return (
    <div className={classNames(cn.root, className)}>
      <Grid
        rows={tableData}
        columns={columns}
      >
        <SortingState defaultSorting={defaultSorting} />
        <FilteringState defaultFilters={[]} />
        <IntegratedSorting
          columnExtensions={[
            { columnName: 'supplierCard', compare: sortBySupplierName },
          ]}
        />
        <RowDetailState
          expandedRowIds={expandedRowIds}
          onExpandedRowIdsChange={handleExpandedToggle}
        />
        <IntegratedFiltering />
        <EditingState
          onCommitChanges={(): void => { }}
        />
        {formatNumberWithSpace && (
          <NumberTypeProvider for={formatNumberWithSpace} />
        )}
        <PercentTypeProvider for={['percent']} />
        {formatBoolean && (
          <BooleanTypeProvider for={formatBoolean} />
        )}
        <Table
          containerComponent={TableContainer}
          tableComponent={TableComponent}
          cellComponent={Cell}
          noDataCellComponent={NoDataCell}
          columnExtensions={columnExtensions}
          stubHeaderCellComponent={StubCell}
        />
        <TableHeaderRow
          showSortingControls
          cellComponent={HeaderCell}
          messages={{ sortingHint: '' }}
        />
        {editFormRender && edit && (
          <TableRowDetail
            contentComponent={EditForm}
            toggleCellComponent={ToggleEditCell}
          />
        )}
        {filter && (
          <TableFilterRow
            cellComponent={FilterCell}
          />
        )}
        {!editFormRender && edit && (
          <TableEditColumn
            showEditCommand
            width={55}
            headerCellComponent={EditCellHeader}
            cellComponent={EditCell}
          />
        )}
        {columnBands && (
          <TableBandHeader
            cellComponent={BandCell}
            columnBands={columnBands}
          />
        )}
      </Grid>
    </div>
  );
};
