import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import api from 'api';
import { Button } from 'components/common/Button/Button';
import { selectSelectedSupplierId } from 'store/suppliersList/Selector';
import { startLoading, finishLoading } from 'hooks/useProgress';
import { SupplierId } from 'store/suppliersList/types';
import { AppState } from 'store/RootReducer';
import { Review } from 'components/SupplierCard/Tabs/ReviewTab/types';
import { showSnackbar } from 'hooks/useSnackbar';
import cn from './ReviewTab.module.scss';

type MapStateToProps = {
  selectedSupplierId: SupplierId;
};

type ReviewTabProps = MapStateToProps;

const fields = {
  GENERAL: 'general',
  EQUIPMENT: 'equipment',
  ENVIRONMENT: 'environment',
  COVERAGE: 'coverage',
  IT: 'it',
  DEVELOPMENT: 'development',
};

const initialReview :Review = {
  id:0,
  general: '',
  equipment: '',
  environment: '',
  coverage: '',
  it: '',
  development: '',
  supplierCardId: '',
};

const ReviewTabComponent = React.memo(
  ({ selectedSupplierId }: ReviewTabProps) => {
    const [review, setReview] = useState<Review>(initialReview);

    const requestData = useCallback(async () => {
      startLoading();
      try {
        const dbReview = await api.getReview(selectedSupplierId);
        setReview(dbReview || initialReview);
      } catch (err) {}
      finishLoading();
    }, [selectedSupplierId]);

    function handleInputChange(
      event: ChangeEvent<HTMLInputElement>,
      field: string
    ): void {
      setReview({
        ...review,
        [field]: event.target.value,
      });
    }

    async function handleSaveButton(): Promise<void> {
      startLoading();
      try {
        await api.editReview(selectedSupplierId, review);
        showSnackbar({
          message: 'Review was updated.',
          type: 'success',
        });
      } catch (err) {}
      finishLoading();
    }

    useEffect(() => {
      if (selectedSupplierId) {
        requestData();
      }
    }, [selectedSupplierId, requestData]);

    return (
      <div className={cn.reviewContainer}>
        <div className={cn.row}>
          <TextField
            label="General"
            name="general"
            onChange={(event: ChangeEvent<HTMLInputElement>): void =>
              handleInputChange(event, fields.GENERAL)
            }
            value={review.general ?? ''}
            fullWidth
            multiline
            minRows={3}
            maxRows={5}
            variant="outlined"
            InputProps={{
              classes: {
                root: cn.inputRoot,
                notchedOutline: cn.noBorderRadius,
              },
            }}
            InputLabelProps={{
              shrink: !!review.general,
            }}
          />
          <TextField
            label="Equipment"
            name="equipment"
            onChange={(event: ChangeEvent<HTMLInputElement>): void =>
              handleInputChange(event, fields.EQUIPMENT)
            }
            value={review.equipment ?? ''}
            fullWidth
            multiline
            minRows={3}
            maxRows={5}
            variant="outlined"
            InputProps={{
              classes: {
                root: cn.inputRoot,
                notchedOutline: cn.noBorderRadius,
              },
            }}
             InputLabelProps={{
               shrink: !!review.equipment,
             }}
          />
        </div>
        <div className={cn.row}>
          <TextField
            label="Environment"
            name="environment"
            onChange={(event: ChangeEvent<HTMLInputElement>): void =>
              handleInputChange(event, fields.ENVIRONMENT)
            }
            value={review.environment ?? ''}
            fullWidth
            multiline
            minRows={3}
            maxRows={5}
            variant="outlined"
            InputProps={{
              classes: {
                root: cn.inputRoot,
                notchedOutline: cn.noBorderRadius,
              },
            }}
            InputLabelProps={{
              shrink: !!review.environment,
            }}
          />
          <TextField
            label="Coverage"
            name="coverage"
            onChange={(event: ChangeEvent<HTMLInputElement>): void =>
              handleInputChange(event, fields.COVERAGE)
            }
            value={review.coverage ?? ''}
            fullWidth
            multiline
            minRows={3}
            maxRows={5}
            variant="outlined"
            InputProps={{
              classes: {
                root: cn.inputRoot,
                notchedOutline: cn.noBorderRadius,
              },
            }}
            InputLabelProps={{
              shrink: !!review.coverage,
            }}
          />
        </div>
        <div className={cn.row}>
          <TextField
            label="IT"
            name="it"
            onChange={(event: ChangeEvent<HTMLInputElement>): void =>
              handleInputChange(event, fields.IT)
            }
            value={review.it ?? ''}
            fullWidth
            multiline
            minRows={3}
            maxRows={3}
            variant="outlined"
            InputProps={{
              classes: {
                root: cn.inputRoot,
                notchedOutline: cn.noBorderRadius,
              },
            }}
            InputLabelProps={{
              shrink: !!review.it,
            }}
          />
          <TextField
            label="Development"
            name="development"
            onChange={(event: ChangeEvent<HTMLInputElement>): void =>
              handleInputChange(event, fields.DEVELOPMENT)
            }
            value={review.development ?? ''}
            fullWidth
            multiline
            minRows={3}
            maxRows={5}
            variant="outlined"
            InputProps={{
              classes: {
                root: cn.inputRoot,
                notchedOutline: cn.noBorderRadius,
              },
            }}
            InputLabelProps={{
              shrink: !!review.development,
            }}
          />
        </div>
        <div className={cn.buttonRow}>
          <Button onClick={handleSaveButton}>Save</Button>
        </div>
      </div>
    );
  }
);

const mapStateToProps = (state: AppState): MapStateToProps => ({
  selectedSupplierId: selectSelectedSupplierId(state),
});

export const ReviewTab = connect(mapStateToProps)(ReviewTabComponent);
