import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { selectSelectedTab } from 'store/tabs/Selector';
import { FilesTab } from 'components/SupplierCard/Tabs/FilesTab/FilesTab';
import { BusinessAreaTab } from 'components/SupplierCard/Tabs/BusinessAreaTab/BusinessAreaTab';
import { ContactPersonsTab } from 'components/SupplierCard/Tabs/ContactPersonsTab/ContactPersonsTab';
import { SupplierNrTab } from 'components/SupplierCard/Tabs/SupplierNrTab/SupplierNrTab';
import { DestinationsTab } from 'components/SupplierCard/Tabs/DestinationsTab/DestinationsTab';
import { ReviewTab } from 'components/SupplierCard/Tabs/ReviewTab/ReviewTab';
import ConnectedSrmSupplierCardProfitabilityTabContainer from 'containers/srm/SrmSupplierCardProfitabilityTabContainer';
import { setSelectedTab } from 'store/tabs/ActionCreator';
import { selectIsAuthenticated } from 'store/microsoftGraph/Selector';
import cn from './SupplierCardTabs.module.scss';

const Tabs = {
  profitability: 0,
  businessArea: 1,
  files: 2,
  supplierNr: 3,
  review: 4,
  contactPersons: 5,
  destinations: 6
};

export const SupplierCardTabs = () => {
  const selectedTab = useSelector(selectSelectedTab);
  const dispatch = useDispatch();
  const authenticated = useSelector(selectIsAuthenticated);

  function handleTabChange(tab: number): void {
    dispatch(setSelectedTab(tab));
  }

  return (
    <div>
      <div className={cn.tabs}>
        <button
          type="button"
          className={classNames(cn.tab, cn.blueTab, { [cn.selectedTab]: selectedTab === Tabs.profitability })}
          onClick={() => handleTabChange(Tabs.profitability)}
        >
          Profitability
        </button>
        <button
          type="button"
          className={classNames(cn.tab, cn.blueTab, { [cn.selectedTab]: selectedTab === Tabs.businessArea })}
          onClick={() => handleTabChange(Tabs.businessArea)}
        >
          Business Area
        </button>
        <button
          type="button"
          disabled={!authenticated}
          className={classNames(cn.tab, cn.blueTab,
            { [cn.selectedTab]: selectedTab === Tabs.files }, { [cn.disabled]: !authenticated })}
          onClick={() => handleTabChange(Tabs.files)}
        >
          Files
        </button>
        <button
          type="button"
          className={classNames(cn.tab, cn.orangeTab, { [cn.selectedTab]: selectedTab === Tabs.supplierNr })}
          onClick={() => handleTabChange(Tabs.supplierNr)}
        >
          Supplier Nr
        </button>
        <button
          type="button"
          className={
            classNames(cn.tab, cn.orangeTab, { [cn.selectedTab]: selectedTab === Tabs.review })
          }
          onClick={() => handleTabChange(Tabs.review)}
        >
          Review
        </button>
        <button
          type="button"
          className={classNames(cn.tab, cn.orangeTab, { [cn.selectedTab]: selectedTab === Tabs.contactPersons })}
          onClick={() => handleTabChange(Tabs.contactPersons)}
        >
          Contact persons
        </button>
        <button
          type="button"
          className={classNames(cn.tab, cn.orangeTab, { [cn.selectedTab]: selectedTab === Tabs.destinations })}
          onClick={() => handleTabChange(Tabs.destinations)}
        >
          Destinations
        </button>
      </div>
      <div className={cn.tabContent}>
        {selectedTab === Tabs.profitability && <ConnectedSrmSupplierCardProfitabilityTabContainer />}
        {selectedTab === Tabs.businessArea && <BusinessAreaTab />}
        {selectedTab === Tabs.files && <FilesTab />}
        {selectedTab === Tabs.supplierNr && <SupplierNrTab />}
        {selectedTab === Tabs.review && <ReviewTab />}
        {selectedTab === Tabs.contactPersons && <ContactPersonsTab />}
        {selectedTab === Tabs.destinations && <DestinationsTab />}
      </div>
    </div>
  );
};
